.control-buttons{
    background: none;
    border: none;
}

.previewIcon::before{
    content: "\f06e";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.updateIcon::before{
    content: "\f044";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.editIcon::before{
    content: "\f040";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.deleteIcon::before{
    content: "\f2ed";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.hardDeleteIcon::before{
    content: "\f1f8";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #ef0808;
}

.copyIcon::before{
    content: "\f0c5";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.dragIcon::before{
    content: "\f047";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.dashboardIcon::before{
    content: "\f080";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.notificationIcon::before{
    content: "\f0a2";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.studyIcon::before{
    content: "\f2bb";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.visitIcon::before{
    content: "\f0f8";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.formIcon::before{
    content: "\f478";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.siteIcon::before{
    content: "\f0e8";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.userIcon::before{
    content: "\f0c0";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.subjectIcon::before{
    content: "\f007";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.auditLogIcon::before{
    content: "\f187";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.reportIcon::before{
    content: "\f15b";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.questionIcon::before{
    content: "\f059";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #ffff;
}

.questionQueryIcon::before{
    content: "\f059";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: lighter;
    text-decoration: inherit;
    font-size: large;
}

.dataNotAvailableIcon::before{
    content: "\f057";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: lighter;
    text-decoration: inherit;
    font-size: medium;
}

.studyDetailIcon::before{
    content: "\f02d";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #ffff;
}

.excelReportIcon::before{
    content: "\f1c3";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.pdfReportIcon::before{
    content: "\f1c1";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.queryIcon::before{
    content: "\f086";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.dataTableIcon::before{
    content: "\f0db";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.signFormIcon::before{
    content: "\f573";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.fillUpFormIcon::before{
    content: "\f477";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
}

.lockIcon::before{
    content: "\f023";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.unLockIcon::before{
    content: "\f3c1";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.resetIcon::before{
    content: "\f021";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.signInIcon::before{
    content: "\f090";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.signOutIcon::before{
    content: "\f08b";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.keyIcon::before{
    content: "\f084";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.plusIcon::before{
    content: "\f067";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.showPassword::before{
    content: "\f070";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.showPasswordView::before{
    content: "\f06e";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.saveIcon::before{
    content: "\f0c7";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.minusIcon::before{
    content: "\f068";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.threeDotVerticalIcon::before{
    content: "\f142";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.mergeFromLeft::before{
    content: "\f104";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.mergeFromRight::before{
    content: "\f105";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.mergeFromTop::before{
    content: "\f106";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.mergeFromBottom::before{
    content: "\f107";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.splitFromLeft::before{
    content: "\f060";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.splitFromRight::before{
    content: "\f061";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.splitFromBottom::before{
    content: "\f063";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.splitColumn::before{
    content: "\f07e";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.splitRow::before{
    content: "\f07d";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.copyCellIcon::before{
    content: "\f38c";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.dragBarIcon::before{
    content: "\f0c9";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

/* .formulaIcon::before{
    content: "\0192";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
} */

.infoIcon::before{
    content: "\f05a";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}
.subMenuIcon::before{
    content: "\f068";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    font-size: x-small;
    text-decoration: inherit;
}
.historyAuditLogIcon::before{
    content: "\f1da";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}
.reportMasterIcon::before{
    content: "\f0f6";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.commentIcon::before{
    content: "\f0e5";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.unScheduleVisitIcon::before{
    content: "\f196";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.importIcon::before{
    content: "\f56f";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.exportToProductionIcon::before{
    content: "\f56e";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.checkIcon::before{
    content: "\f00c";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.loginUserIcon1:before {
    content: "\f007";
    font-family: FontAwesome;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: relative;
    top: 39px;
    right: 213px;
    color: #6cb1de;
}

.lockIcon1:before {
    content: "\f023";
    font-family: FontAwesome;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: relative;
    top: 39px;
    right: 213px;
    color: #6cb1de;
}

.loginUserIcon:before {
    content: "\f007";
    font-family: FontAwesome;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.lockIcon:before {
    content: "\f023";
    font-family: FontAwesome;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.plusSignIcon:before {
    content: "\f067";
    font-family: FontAwesome;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.crossSignIcon:before {
    content: "\f00d";
    font-family: FontAwesome;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color:red;
    cursor:pointer;
}

.submitForReviewIcon:before {
    content: "\f1d9";
    font-family: FontAwesome;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.sdvIcon:before {
    content: "\f111";
    font-family: FontAwesome;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.dmIcon:before {
    content: "\f0c8";
    font-family: FontAwesome;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.invIcon:before {
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}
